import {
    Column,
    Divider,
    EComponentSize,
    ERowAlignment,
    ERowJustification,
    Label,
    Row,
    ScrollableArea,
    Select,
    useModals,
} from '@hyperclap/ui';
// eslint-disable-next-line import/no-unresolved
import config from 'config';
import { TOptions } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';

import { IconPlusLine } from '@assets/images/svg';
import { ToolButton } from '@components';
import { NotificationType } from '@contexts';
import { TApp } from '@hooks';
import { NEW_CATEGORY_ID } from '@hooks/app/settings/Voices';
import { IVoice, IVoiceDescriptor, IVoicesCategory, TNullable } from '@typings';
import { VoiceDescriptorInfo } from '@views';

import s from './Desktop.scss';
import { Voice, VoiceCategory, VoiceDescriptor } from '../../components';

interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {
        notifications: {
            notify,
        },
        settings: {
            voices: {
                categories,
                providers,
                activeCategory,
                editedCategory,
                selectedProvider,
                selectedProviderVoices,
                voiceTags,
                activateCategory,
                addVoiceCategory,
                cancelVoiceCategoryEditing,
                editVoiceCategory,
                saveVoiceCategory,
                deleteVoiceCategory,
                selectProvider,
                buildVoiceDescriptor,
                addVoiceToCategory,
                removeVoiceFromCategory,
                updateVoiceInCategory,
            },
        },
        translation: {
            t: translate,
        },
    } = props;

    const { showModal, closeModal } = useModals();

    const [playedVoice, setPlayedVoice] = useState<TNullable<IVoice>>(null);
    const [voicesInCategory, setVoicesInCategory] = useState<Array<IVoiceDescriptor>>([]);

    const player = useRef<HTMLAudioElement>(null);

    const t = (text: string, opts?: TOptions) => translate(`app.settings.tts.${text}`, opts);

    const onAddNewCategoryClick = () => {
        const cat = addVoiceCategory();
        activateCategory(cat);
    };

    const onCategoryClick = (category: IVoicesCategory) => {
        if (category.id === activeCategory?.id) {
            editVoiceCategory(category.id);
        } else {
            activateCategory(category);
        }
    };

    const onVoicePlayClick = (voice: IVoice) => {
        const ttsProxy = config?.hosts?.ttsProxyApiHost;

        if (!ttsProxy) {
            notify(t('notifications.no-tts-proxy'), NotificationType.ERROR);

            return;
        }

        if (player.current) {
            const text = 'Привет! У тебя классный стрим. Люблю тебя!';
            player.current.src = `${
                ttsProxy
            }/tts/convert?providerName=${
                selectedProvider
            }&voiceId=${
                voice.name
            }&text=${
                text
            }&language=${
                voice.languages[0]
            }&engine=${
                voice.supportedEngines[0]
            }&outputFormat=ogg`;

            player.current.onended = () => {
                if (player.current) {
                    player.current.onended = null;
                    setPlayedVoice(null);
                }
            };

            player.current.onerror = () => {
                if (player.current) {
                    player.current.onended = null;
                    setPlayedVoice(null);
                }
            };

            setPlayedVoice(voice);
            void player.current.play();
        }
    };

    const onVoicePlusClick = (voice: IVoice) => {
        if (selectedProvider) {
            const descriptor = buildVoiceDescriptor(selectedProvider, voice);
            addVoiceToCategory(descriptor);
        }
    };

    const onSaveVoiceDescriptor = (descriptor: IVoiceDescriptor) => {
        updateVoiceInCategory(descriptor);
        closeModal();
    };

    const onCancelEditVoiceDescriptor = () => {
        closeModal();
    };

    const onVoiceDescriptionClick = (descriptor: IVoiceDescriptor) => {
        showModal({
            content: <VoiceDescriptorInfo
                descriptor={descriptor}
                category={activeCategory?.name}
                tags={voiceTags}
                onClose={closeModal}
                onCancel={onCancelEditVoiceDescriptor}
                onOk={onSaveVoiceDescriptor}
            />,
            options: {
                className: s.voiceCategoryInfo,
                bodyClassName: s.voiceCategoryInfoBody,
                preventCloseOnShadow: true,
            },
        });
    };

    const categoriesElements = categories.map((category, idx) => (
        <VoiceCategory
            active={activeCategory?.id === category.id}
            key={idx}
            category={category}
            editMode={false}
            onClick={onCategoryClick}
            onApplyEdit={saveVoiceCategory}
            onCancelEdit={cancelVoiceCategoryEditing}
            onDeleteClick={deleteVoiceCategory}
        />
    ));

    useEffect(() => {
        setVoicesInCategory([...activeCategory?.voices ?? []]);
    }, [activeCategory]);

    return (
        <Column grow>
            <Label padding={0} caption={t('page-title')} size={EComponentSize.MEDIUM} useBoldFont />
            <Divider/>
            <Row grow columnGap={10} alignment={ERowAlignment.STRETCH}>
                <Column rowGap={10} className={s.voiceCategories}>
                    <Row padding={{ left: 10, right: 0 }} justification={ERowJustification.SPACE_BETWEEN} columnGap={25}>
                        <Label caption={'Категории голосов'} useBoldFont />
                        <ToolButton icon={<IconPlusLine/>} onClick={onAddNewCategoryClick}/>
                    </Row>
                    <Column rowGap={5} grow>
                        <ScrollableArea className={s.voiceCategoriesScroll}>
                            { !!editedCategory && editedCategory?.id === NEW_CATEGORY_ID &&
                                <VoiceCategory
                                    active={true}
                                    category={editedCategory}
                                    editMode={true}
                                    onApplyEdit={saveVoiceCategory}
                                    onCancelEdit={cancelVoiceCategoryEditing}
                                />
                            }
                            { categoriesElements }
                        </ScrollableArea>
                    </Column>
                </Column>

                <Column grow className={s.voicesInCategory}>
                    <Label
                        padding={{ left: 10, right: 10 }}
                        caption={t('voices-in-category', { categoryName: activeCategory?.name || '' })}
                        useBoldFont
                    />
                    <Row
                        columnGap={5}
                        padding={10}
                        alignment={ERowAlignment.TOP}
                        className={s.voicesInCategoryVoices}
                    >
                        { voicesInCategory.map((voiceDescriptor) => (
                            <VoiceDescriptor
                                key={voiceDescriptor.id}
                                descriptor={voiceDescriptor}
                                onChanged={updateVoiceInCategory}
                                onRemoveClick={removeVoiceFromCategory}
                                onInfoClick={onVoiceDescriptionClick}
                            />
                        ))}
                    </Row>
                </Column>

                <Column grow padding={10} rowGap={10} className={s.provider}>
                    <Label padding={0} caption={t('provider-dd-title')}/>
                    { providers?.length &&
                        <Select
                            options={providers.map((p, idx) => ({ caption: p, selected: idx === 0 }))}
                            onSelectionChanged={(selection) => selectProvider(selection?.caption ?? '')}
                        />
                    }
                    { !!selectedProvider &&
                        <>
                            <Label caption={t('provided-voices')}/>
                            <ScrollableArea>
                                { selectedProviderVoices
                                    // .filter((v) => v.languages.includes('ru-RU'))
                                    .sort((a, b) => a.name > b.name ? 1 : -1)
                                    .map((v, idx) => (
                                        <Voice
                                            key={idx}
                                            voice={v}
                                            disabled={playedVoice !== null && playedVoice?.name !== v.name}
                                            onPlayClick={onVoicePlayClick}
                                            onPlusClick={onVoicePlusClick}
                                        />
                                    ))
                                }
                            </ScrollableArea>
                        </>
                    }
                </Column>
            </Row>
            <audio ref={player}/>
        </Column>
    );
};
