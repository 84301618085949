import {
    Button,
    Column,
    EColumnAlignment,
    EColumnJustification,
    EComponentColor,
    EComponentSize,
    ERowAlignment,
    ERowJustification,
    Icon,
    Label,
    Row,
    TextField,
} from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
// eslint-disable-next-line import/no-unresolved
import config from 'config';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconPauseLine, IconPlayLine, IconXLine } from '@assets/images/svg';
import { IVoiceDescriptor, IVoiceTag } from '@typings';

import { VoiceTag } from './components';
import s from './VoiceDescriptorInfo.scss';

interface IVoiceDescriptorInfoProps {
    descriptor: IVoiceDescriptor;
    category?: string;
    tags?: Array<IVoiceTag>,
    onClose?: () => void;
    onOk?: (changedDescriptor: IVoiceDescriptor) => void;
    onCancel?: () => void;
    onPlayAction?: (descriptor: IVoiceDescriptor) => void;
}

export const VoiceDescriptorInfo = (props: IVoiceDescriptorInfoProps) => {
    const {
        descriptor,
        category = '',
        tags = [],
        onClose = noop,
        onOk = noop,
        onCancel = noop,
    } = props;

    const player = useRef<HTMLAudioElement>(null);

    const [descriptorInstance, setDescriptorInstance] = useState<IVoiceDescriptor>({ ...descriptor });
    const [voiceName, setVoiceName] = useState<string>(descriptor.name ?? descriptor.voice);
    const [isPlaying, setIsPlaying] = useState(false);

    const { t: translate } = useTranslation();
    const t = (token: string) => translate(`app.settings.tts.modals.voice-descriptor-info.${token}`);

    const onVoiceNameChanged = (val: string) => {
        setVoiceName(val);
    };

    const onAction = () => {
        const ttsProxy = config?.hosts?.ttsProxyApiHost;

        if (!ttsProxy) {
            return;
        }

        if (isPlaying && player.current) {
            player.current.pause();
            player.current.src = '';
            setIsPlaying(false);

            return;
        }

        if (player.current) {
            const text = descriptor.phrase
                ?.replace('{name}', descriptor.name)
                ?.replace('{category}', category)|| 'Привет! У тебя классный стрим. Люблю тебя!';
            player.current.src = `${
                ttsProxy
            }/tts/convert?providerName=${
                descriptor.provider
            }&voiceId=${
                descriptor.voice
            }&text=${
                text
            }&outputFormat=ogg&ignoreCache=true`;

            player.current.onended = () => {
                if (player.current) {
                    player.current.onended = null;
                    setIsPlaying(false);
                }
            };

            player.current.onerror = () => {
                if (player.current) {
                    player.current.onended = null;
                    setIsPlaying(false);
                }
            };

            setIsPlaying(true);

            void player.current.play();
        }
    };

    const onTagClick = (tag: IVoiceTag) => {
        const newTags = descriptorInstance.tags?.includes(tag.tag)
            ? descriptorInstance.tags?.filter((t) => t !== tag.tag)
            : [tag.tag].concat(descriptorInstance.tags ?? []);

        setDescriptorInstance({
            ...descriptorInstance,
            tags: [...newTags],
        });
    };

    useEffect(() => {
        setDescriptorInstance({ ...descriptorInstance, name: voiceName });
    }, [voiceName]);

    return (
        <Column grow rowGap={10} alignment={EColumnAlignment.STRETCH} className={s.voiceDescriptorInfo}>
            <Row>
                <TextField
                    caption={t('name')}
                    defaultValue={voiceName}
                    wrapperClassName={s.name}
                    onChanged={onVoiceNameChanged}
                    onAction={onAction}
                    actions={[
                        { icon: isPlaying ? <IconPauseLine/> : <IconPlayLine/>, name: 'play' },
                    ]}
                />
            </Row>

            <Row alignment={ERowAlignment.TOP}>
                <Column grow justification={EColumnJustification.TOP}>
                    <Label caption={t('information')} size={EComponentSize.MEDIUM}/>

                    <Row columnGap={5}>
                        <Label caption={t('provider') + ':'} size={EComponentSize.SMALL} useBoldFont muted/>
                        <Label caption={descriptor.provider} size={EComponentSize.SMALL}/>
                    </Row>

                    <Row columnGap={5}>
                        <Label caption={t('voice') + ':'} size={EComponentSize.SMALL} useBoldFont muted/>
                        <Label caption={descriptor.voice} size={EComponentSize.SMALL}/>
                    </Row>
                </Column>
            </Row>

            <Row alignment={ERowAlignment.TOP}>
                <Column grow justification={EColumnJustification.TOP}>
                    <Label caption={t('tags')} size={EComponentSize.MEDIUM}/>

                    <Row columnGap={5} className={s.tagsContainer}>
                        {tags.map((tag) => (
                            <VoiceTag
                                key={tag.id}
                                tag={tag}
                                selected={descriptorInstance.tags?.includes(tag.tag)}
                                onClick={onTagClick}
                            />
                        ))}
                    </Row>
                </Column>
            </Row>

            <Row columnGap={10} justification={ERowJustification.END}>
                <Button caption={t('save')} color={EComponentColor.ACCENT} onClick={() => onOk(descriptorInstance)}/>
                <Button caption={t('cancel')} color={EComponentColor.WHITE} onClick={onCancel}/>
            </Row>

            <Icon
                icon={<IconXLine/>}
                size={EComponentSize.SMALL}
                className={s.closeButton}
                onClick={onClose}
            />

            <audio ref={player}/>
        </Column>
    );
};
