import { LoggerColor, useLogger } from '@hyperclap/ui';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

import { useApi, useTtsApi } from '@hooks';
import { IVoice, IVoiceDescriptor, IVoicesCategory, TNullable } from '@typings';


interface IVoiceSettingsState {
    availableProviders: Array<string>;
    editedCategory: TNullable<IVoicesCategory>;
    voiceCategories: Array<IVoicesCategory>;
    voicesInCategories: Record<string, Array<IVoiceDescriptor>>;
    activeCategory: TNullable<IVoicesCategory>;
    selectedProvider?: string;
    selectedProviderVoices: Array<IVoice>;
}

export const NEW_CATEGORY_ID = '__NEW__';

const CATEGORY_BLANK_TEMPLATE: IVoicesCategory = {
    id: NEW_CATEGORY_ID,
    name: '',
    description: '',
    order: 0,
    hidden: false,
    voices: [],
};

const DEFAULT_VOICE_SETTINGS_STATE: IVoiceSettingsState = {
    availableProviders: [],
    editedCategory: null,
    voiceCategories: [],
    voicesInCategories: {},
    activeCategory: null,
    selectedProvider: '',
    selectedProviderVoices: [],
};

export const useVoices = () => {
    const logger = useLogger({ target: useVoices.name, showTimestamp: true });
    const {
        settings: {
            useLoadVoiceCategoriesQuery,
            useCreateVoiceCategoryMutation,
            useUpdateVoiceCategoryMutation,
            useDeleteVoiceCategoryMutation,
            useLoadVoiceTagsQuery,
        },
    } = useApi();

    const {
        providers: {
            useListProvidersQuery,
            useLazyGetProviderVoicesQuery,
        },
    } = useTtsApi();

    const [state, setState] = useState<IVoiceSettingsState>(DEFAULT_VOICE_SETTINGS_STATE);
    const { data: voiceTags } = useLoadVoiceTagsQuery();

    const {
        data: loadedProviders,
    } = useListProvidersQuery();

    const {
        data: loadedVoiceCategories,
        isLoading: isVoiceCategoriesLoading,
    } = useLoadVoiceCategoriesQuery();

    const [
        createVoiceCategory,
    ] = useCreateVoiceCategoryMutation();

    const [
        updateVoiceCategory,
    ] = useUpdateVoiceCategoryMutation();

    const [
        dropVoiceCategory,
    ] = useDeleteVoiceCategoryMutation();

    const [
        loadProviderVoices,
    ] = useLazyGetProviderVoicesQuery();

    const addVoiceCategory = () => {
        const newVoiceCategory = { ...CATEGORY_BLANK_TEMPLATE };

        setState((state) => ({
            ...state,
            editedCategory: newVoiceCategory,
        }));

        return newVoiceCategory;
    };

    const editVoiceCategory = (id: string) => {
        logger.debug(`Requested category editing: ${id}`, LoggerColor.TEAL);

        setState((state) => ({
            ...state,
            editedCategory: state.voiceCategories.find((c) => c.id === id) ?? null,
        }));
    };

    const saveVoiceCategory = (name: string) => {
        if (state.editedCategory === null) return;

        if (state.editedCategory?.id === NEW_CATEGORY_ID) {
            createVoiceCategory({ ...state.editedCategory, name });
        } else if (state.editedCategory) {
            updateVoiceCategory({ ...state.editedCategory, name });
        }

        setState((state) => ({
            ...state,
            editedCategory: null,
        }));
    };

    const cancelVoiceCategoryEditing = () => {
        if (state.editedCategory?.id === NEW_CATEGORY_ID) {
            setState((state) => ({
                ...state,
                editedCategory: null,
            }));
        }
    };

    const deleteVoiceCategory = (categoryId: string) => {
        dropVoiceCategory(categoryId);
    };

    const selectProvider = async (provider: string) => {
        const loadedVoices = await loadProviderVoices(provider).unwrap();

        if (loadedVoices.length) {
            setState((state) => ({
                ...state,
                selectedProvider: provider,
                selectedProviderVoices: [...loadedVoices],
            }));
        }
    };

    const activateCategory = (category: IVoicesCategory) => {
        setState((state) => ({
            ...state,
            activeCategory: category,
        }));
    };

    const buildVoiceDescriptor = (provider: string, voice: IVoice) => {
        const descriptor: IVoiceDescriptor = {
            id: v4(),
            provider,
            voice: voice.name,
            name: voice.name,
            parameters: {
                provider,
                engine: voice.supportedEngines[0],
                output: 'ogg',
            },
        };

        return descriptor;
    };

    const addVoiceToCategory = (voiceDescriptor: IVoiceDescriptor) => {
        if (state.activeCategory !== null) {
            updateVoiceCategory({
                ...state.activeCategory,
                voices: [
                    ...state.activeCategory.voices,
                    voiceDescriptor,
                ],
            });
        }
    };

    const removeVoiceFromCategory = (voiceDescriptor: IVoiceDescriptor) => {
        if (state.activeCategory !== null) {
            updateVoiceCategory({
                ...state.activeCategory,
                voices: [
                    ...state.activeCategory.voices.filter((c) => c.id !== voiceDescriptor.id),
                ],
            });
        }
    };

    const updateVoiceInCategory = (voiceDescriptor: IVoiceDescriptor) => {
        if (state.activeCategory !== null) {
            updateVoiceCategory({
                ...state.activeCategory,
                voices: [
                    ...state.activeCategory.voices.map((d) => d.id === voiceDescriptor.id
                        ? voiceDescriptor
                        : d,
                    ),
                ],
            });
        }
    };

    useEffect(() => {
        setState((state) => ({
            ...state,
            availableProviders: loadedProviders ?? [],
        }));
    }, [loadedProviders]);

    useEffect(() => {
        if (loadedVoiceCategories) {
            setState((state) => ({
                ...state,
                voiceCategories: loadedVoiceCategories ?? [],
                activeCategory: loadedVoiceCategories?.find((c) => state.activeCategory?.id === c.id) ??
                    loadedVoiceCategories[0] ?? null,
            }));

            const updatedActiveCategory = loadedVoiceCategories.find((c) => c.id === state.activeCategory?.id);

            if (state.activeCategory && updatedActiveCategory) {
                setState((state) => ({
                    ...state,
                    activeCategory: updatedActiveCategory,
                }));
            }
        }
    }, [loadedVoiceCategories]);

    return {
        activeCategory: state.activeCategory,
        isVoiceCategoriesLoading,
        categories: state.voiceCategories,
        providers: state.availableProviders,
        editedCategory: state.editedCategory,
        selectedProvider: state.selectedProvider,
        selectedProviderVoices: state.selectedProviderVoices,
        voiceTags,

        activateCategory,
        addVoiceCategory,
        cancelVoiceCategoryEditing,
        editVoiceCategory,
        saveVoiceCategory,
        deleteVoiceCategory,
        selectProvider,
        buildVoiceDescriptor,
        addVoiceToCategory,
        removeVoiceFromCategory,
        updateVoiceInCategory,
    };
};
