import { Logger } from '@hyperclap/ui';

import { IVoicesCategory, IVoiceTag, TSettingsList } from '@typings';

import { baseApi } from './base.api';

const logger = new Logger({ target: 'SettingsApi', showTimestamp: true });
logger.trace(`Configure Settings API`);

export const settingsApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Settings_List',
            'Settings_VoicesCategoriesList',
            'Settings_VoicesDescriptorsList',
            'Settings_VoiceTagsList',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            loadSettings: build.query<TSettingsList, void>({
                query: () => ({
                    url: 'settings',
                    method: 'GET',
                }),
                providesTags: ['Settings_List'],
            }),

            loadVoiceTags: build.query<Array<IVoiceTag>, void>({
                query: () => ({
                    url: 'settings/voice-tags',
                    method: 'GET',
                }),
                providesTags: ['Settings_VoiceTagsList'],
            }),

            loadVoiceCategories: build.query<Array<IVoicesCategory>, void>({
                query: () => ({
                    url: 'settings/voices-categories',
                    method: 'GET',
                }),
                providesTags: ['Settings_VoicesCategoriesList'],
            }),

            createVoiceCategory: build.mutation<IVoicesCategory, Omit<IVoicesCategory, 'id'>>({
                query: (data: IVoicesCategory) => ({
                    url: 'settings/voices-categories',
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['Settings_VoicesCategoriesList'],
            }),

            updateVoiceCategory: build.mutation<IVoicesCategory, IVoicesCategory>({
                query: (data: IVoicesCategory) => ({
                    url: `settings/voices-categories/${data.id}`,
                    method: 'PUT',
                    body: data,
                }),
                invalidatesTags: ['Settings_VoicesCategoriesList'],
            }),

            deleteVoiceCategory: build.mutation<void, string>({
                query: (id: string) => ({
                    url: `settings/voices-categories/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Settings_VoicesCategoriesList'],
            }),
        }),
    });
